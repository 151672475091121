import { Config } from "../../interfaces/config";
import { FeriproPayment } from "../../services/payment.service";
import { AccountType } from "../../services/question.service";
import { Event } from "../event/event.interface";
import { BookingQuestion } from "../question/custom-question.interface";
import { ParticipantModel } from "../registration/participant-model.class";

export class Registration {

  // necessary for feripro booking
  event_id: number;
  custom_questions: BookingQuestion[];
  group_code: string;
  price_category: number;
  special_notes?; // TODO where are these displayed in feripro?
  

  // added & used by us only
  zi: {
    priority: number;
    relative_id: string;
    name: string;
    display_normalpreis: boolean;
    groups_done: boolean;
    price_category_is_locked: boolean;
    price: number;
  };

  static instantiate_from_event(event: Event): Registration {
    return {
      event_id: event.event_id || null,
      custom_questions: event.registration_custom_fields.map(q => ({...q, answer: ""})) || [],
      group_code: "",
      price_category: 0,
      special_notes: null,
      zi: {
        priority: 0,
        relative_id: event.relative_id,
        name: event.name || "",
        display_normalpreis: event.display_normalpreis,
        price_category_is_locked: false,
        price: event.price || 0,
        groups_done: false,
      },
    };
  }
}

export class Reservation {

  // necessary for feripro booking

  participant: Readonly<ParticipantModel>;
  registrations: Registration[]; // erstmal leer
  payment: FeriproPayment;


  // added & used by us only

  /** AccountType of participant */
  type: AccountType;
  response_error_message?: string; // TODO use more often than only in confirmPage?

  cache: {  // ids out of our backend
    /** id to the cached reservation in our backend. Set if already created */
    id?: number;
    /** program_id to connect the reservation to a (feripro) program */
    program_id: number;
    /** account user id */
    user: number;
    /** set if type === AccountType.CHILD, else null */
    child_obj: number | null;
    /** set if type === AccountType.SECOND_LEGAL_GUARDIAN, else null */
    other_participant: number | null;
  };


  static equals(res_1: Reservation, res_2: Reservation): boolean {
    return res_1.type === res_2.type &&
      res_1.cache.program_id === res_2.cache.program_id &&
      res_1.cache.user === res_2.cache.user &&
      res_1.cache.other_participant === res_2.cache.other_participant &&
      res_1.cache.child_obj === res_2.cache.child_obj;
  }

  static is_valid(res: Reservation, config: Config): boolean {
    if (
      !res?.cache?.user || !res.cache.program_id || !res.type || !res.participant ||
      (res.registrations.length && res.registrations.some(reg => !reg.zi))) {
        return false;
      }
    
    switch(res.type) {
      case AccountType.OWNER: if (!config.booking_settings.parent_booking_allowed || res.cache.other_participant || res.cache.child_obj) return false; break;
      case AccountType.SECOND_LEGAL_GUARDIAN: if (!config.booking_settings.parent_booking_allowed || !config.booking_settings.second_legal_guardian_allowed || !res.cache.other_participant || res.cache.child_obj) return false; break;
      case AccountType.CHILD: if (res.cache.other_participant || !res.cache.child_obj) return false; break;
    };

    return true;
  }
}
