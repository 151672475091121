import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { filter, take, map, switchMap } from "rxjs/operators";

import { AsyncDependencyConsumer } from "../base-classes/async-dependency-consumer";
import { Logger } from "../providers/logger";
import { AuthService } from "../services/auth.service";
import {
  BookingStep,
  BookingStepsService,
} from "../services/booking-steps.service";
import { RouterService } from "../services/router.service";

@Injectable({
  providedIn: "root",
})
export class ShoppingCartStepsGuard
  extends AsyncDependencyConsumer
  implements CanActivate, CanActivateChild
{
  private is_ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    undefined
  );

  constructor(
    private bookingsteps_service: BookingStepsService,
    private auth: AuthService,
    private router_service: RouterService
  ) {
    super();
    this.init(bookingsteps_service, auth, router_service);
  }

  protected onReady(): void {
    this.is_ready$.next(true);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const navigating_to_route = (state.url.split("/").slice(0, 3).join("/") +
      "/") as BookingStep;

    return combineLatest([this.is_ready$, this.auth.is_logged_in$()]).pipe(
      filter((are_ready) => are_ready.every((r) => r)),

      switchMap(() => this.bookingsteps_service.get_allowed_pages$()),
      filter((allowed_pages) => allowed_pages !== undefined),
      take(1),

      map((allowed_pages) => {
        const allowed = allowed_pages.includes(navigating_to_route);
        if (!allowed) {
          Logger.warn(navigating_to_route + " is not allowed!");

          const program_id = state.url.split("/").pop();
          this.router_service.navigate(`${allowed_pages[allowed_pages.length-1]}/${program_id}`);
        }

        return allowed;
      })
    );
  }
}
