import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription, tap } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import {
  Registration,
  Reservation,
} from "../models/booking/reservation.interface";
import { ParticipantModel } from "../models/registration/participant-model.class";
import { PriceCategory } from "../models/registration/price-category.interface";
import { Logger } from "../providers/logger";

import { AccountService } from "./account.service";
import { BackendCallService } from "./backend-call.service";
import { ConfigService } from "./config.service";
import { PriceCategoryService } from "./price-category.service";
import { ProgramService } from "./program.service";
import { AccountType, QuestionService } from "./question.service";

interface BackendCacheRegistration {
  id?: number;
  program_id: number;
  child_obj: number;
  other_participant: number;
  registration_object: Reservation
}
@Injectable({
  providedIn: "root",
})
export class ReservationService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private reservations: BehaviorSubject<Reservation[]> = new BehaviorSubject<Reservation[]>(undefined);

  private logged_in: boolean;
  private account_user: number;
  private full_reservations: Reservation[] = [];

  private current_program_id: number;

  constructor(
    private backend: BackendCallService,
    private acc_service: AccountService,
    private program_service: ProgramService,
    private config_service: ConfigService,
    private price_category_service: PriceCategoryService,
    private question_service: QuestionService,
  ) {
    super();
    this.init(backend, acc_service, program_service, price_category_service, question_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.program_service.get_current_program$().pipe(
        tap(program => this.current_program_id = program ? program.program_id : undefined)
      ).subscribe(() => this.update_reservations_from_full_reservations()),

      this.acc_service.get_account$().pipe(
        tap(account => {
          this.logged_in = !!account;
          this.account_user = account?.user;
        }),
      ).subscribe(async () => {

        // load from backend if logged in
        if (this.logged_in) {
          this.full_reservations = await this.api_get_reservations();
        }
    
        // set locally & ready for action
        this.update_reservations_from_full_reservations();
        this.set_ready();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_reservations$(): Observable<Readonly<Reservation>[]> {
    return this.reservations.asObservable();
  }
  public get_reservations(): Readonly<Reservation>[] {
    return this.reservations.getValue();
  }

  private update_reservations_from_full_reservations(): void {
    // change value to empty list if not logged in
    this.reservations.next(this.logged_in ? this.full_reservations.filter(res => res.cache.program_id === this.current_program_id) : []);
  }

  public async save(reservations: Reservation[] = this.get_reservations()): Promise<void> {
    // this.reservation_service.internal_save() is not threadsave
    for (let i = 0; i < reservations.length; i++) {
      await this.internal_save(reservations[i], i+1 === reservations.length);
    }
  }

  private async internal_save(reservation: Reservation, save_changes: boolean): Promise<void> {
    if (!reservation) { return; }

    // delete unused empty reservation
    if (!reservation.registrations?.length && reservation.cache.id) {
      if (await this.api_delete(reservation)) { reservation.cache.id = null; }
    }

    if (reservation.registrations?.length) {

      // set price_category
      const price_categories = this.price_category_service.get_price_categories(reservation);

      for (const event_id of Object.keys(price_categories)) {
        const registration = reservation.registrations.find(reg => reg.event_id === parseInt(event_id));

        if (!registration.zi.price_category_is_locked && price_categories[event_id]) {
          registration.price_category = price_categories[event_id].id;
          registration.zi.price = price_categories[event_id].price;
        }
      }

      // update reservations.registrations[].custom_questions[] to fit the potentially changed questions of feripro
      for (const reg of reservation.registrations) {
        // copy to prevent non-unique answers in QuestionsPage
        reg.custom_questions = JSON.parse(JSON.stringify(this.question_service.get_booking_questions(reg)));
      }
      
      // backend
      const is_create = !reservation.cache.id;
      const result = await (is_create ? this.api_add(reservation) : this.api_update(reservation));

      // TODO handle UPDATE -> false OR CREATE -> undefined because of error better! DON'T JUST IGNORE
      if (!result) {
        Logger.error(`No result on ${is_create ? "create" : "update"} of reservation`, {reservation});
        return;
      }

      if (is_create) { reservation = result as Reservation; }
    }

    // local:
    const existing_reservation = this.full_reservations.find((res) => Reservation.equals(res, reservation));

    // update ..
    if (existing_reservation) {
      this.full_reservations = this.full_reservations.map((res) => (res !== existing_reservation ? res : reservation));
    
    // .. or add
    } else {
      this.full_reservations = [...this.full_reservations, reservation];
    }

    if (save_changes) {
      this.update_reservations_from_full_reservations();
    }
  }

  /**
   * entfernt bestimmte Elemente aus dem Warenkorb
   */
  public async remove_multiple(reservations: Reservation[]): Promise<void> {
    if (!reservations?.length) { return; }

    // remove deleted res without .cache.id from local list
    const deleted_reservations: Reservation[] = reservations.filter(res => !res?.cache.id);
    
    // backend
    for (const reservation of reservations.filter(res => res?.cache.id)) {
      if (await this.api_delete(reservation)) {
        deleted_reservations.push(reservation);
      }
    }

    // local
    this.full_reservations = this.full_reservations.filter(
      (res) => !deleted_reservations.some(deleted_res => Reservation.equals(deleted_res, res))
    );
    this.update_reservations_from_full_reservations();
  }

  /**
   * löscht registration und checkt erneut, ob nächster step nach wie vor möglich ist
   */
  public async remove_registration(
    reservation: Reservation,
    registration: Registration
  ): Promise<void> {
    if (!reservation || !registration) {
      return;
    }

    const own_reservation = this.full_reservations.find(res => Reservation.equals(res, reservation));
    if (!own_reservation) { return; }

    own_reservation.registrations = own_reservation.registrations.filter(
      (reg) => reg.event_id !== registration.event_id
    );

    // delete empty reservation from backend
    if (!own_reservation.registrations.length) {
      if (await this.api_delete(own_reservation)) own_reservation.cache.id = null;
    }

    this.update_reservations_from_full_reservations();
  }

  /**
   * entferne alle gruppen codes
   * ("ohne Gruppencodes fortfahren" Button)
   */
  public remove_group_codes(program_id: number): void {
    this.full_reservations
      .filter(res => res.cache.program_id === program_id)
      .forEach(res => res.registrations.forEach(
        registration => registration.group_code = ""
      ));

    this.update_reservations_from_full_reservations();
  }

  // utility functions to set certain values. Update reservations-observable afterwards

  public set_response_error_message(
    reservation: Reservation,
    message: string
  ): void {
    const own_reservation = this.full_reservations.find((res) => Reservation.equals(res, reservation));

    own_reservation.response_error_message = message;
    this.update_reservations_from_full_reservations();
  }

  public set_token(reservation: Reservation, token: string): void {
    const own_reservation = this.full_reservations.find((res) => res === reservation);

    (own_reservation.participant as ParticipantModel).token = token;
    this.update_reservations_from_full_reservations();
  }

  public set_price_category_manually(
    reservation: Reservation,
    registration: Registration,
    price_category: PriceCategory
  ): void {
    if (!reservation || !registration || !price_category) { return; }

    const own_reservation = this.full_reservations.find(res => Reservation.equals(res, reservation));
    const own_registration: Registration = own_reservation?.registrations.find(
      (res) => res.event_id === registration.event_id
    );
    if (!own_registration) { return; }

    // set price cat manually, don't overwrite automatically with something else on save
    own_registration.zi.price_category_is_locked = true;
    own_registration.zi.price = price_category.price;
    own_registration.price_category = price_category.id;

    this.update_reservations_from_full_reservations();
  }


  // ****************************************
  // ********* API functions start **********
  // ****************************************

  /**
   * holt alle Daten des Warenkorbs vom backend
   * fügt noch notwenige Informationen hinzu
   * speichert die Daten im Gerätespeicher
   * @returns object - wird nicht genutzt
   */
  private api_get_reservations(): Promise<Reservation[]> {
    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/`;

    return (
      // parse registration_object from backend-string to ParticipantModel
      this.backend.get_with_token<BackendCacheRegistration[]>(url).then((response) =>
          response

            // unpack Json for some backwards compatibility
            // TODO remove when all apps are updated
            .map(res => {
              if (typeof res.registration_object !== "string") return res;

              res.registration_object = JSON.parse(res.registration_object);
              return res;
            })

            // build usable objects
            .map((res) => {
              const backend_reservation: Reservation = res.registration_object;
              delete res.registration_object;

              // create registration
              const reservation: Reservation = {
                ...backend_reservation,
                type: res.child_obj ? AccountType.CHILD : (res.other_participant ? AccountType.SECOND_LEGAL_GUARDIAN : AccountType.OWNER),
                cache: {
                  ...res,
                  user: this.account_user
                },
              };
              // cleanups
              (reservation.participant as ParticipantModel).warning_messages = [];
              return reservation;
            })
        )
        // delete those invalid, duplicates & without reservations on backend too
        .then((reservations: Reservation[]) => {
          const valid_res = reservations.filter((res, index, all) =>
            Reservation.is_valid(res, this.config_service.config) &&
            res.registrations.length &&
            all.findIndex(r => Reservation.equals(r, res)) === index
          );

          const invalid_res = reservations
            .filter(res => !valid_res.includes(res))
            .map(res => this.api_delete(res));

          return Promise.allSettled(invalid_res).then(() => valid_res);
        })
        .catch((err) => {
          Logger.error("Error in fetching reservations from backend", {error: err});
          return [];
        })
    );
  }

  /**
   * fügt einen Warenkorb zum Profil hinzu
   * used by participant-registration class
   * @params data object mit Warenkorb-Daten
   * @returns created reservation with new .cache.id
   */
  private api_add(reservation: Reservation): Promise<Reservation> {
    const registration_data: BackendCacheRegistration = this.to_BackendCacheRegistration_object(reservation);
    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/`;

    return this.backend
      .post_with_token<{ id: number }>(url, registration_data)
      .then((response) => {
        reservation.cache.id = response.id;
        return reservation;
      })
      .catch(() => undefined);
  }

  /**
   * Bestehende Warenkorb-Daten werden überschrieben
   * used by participant registration class
   * @params data object mit Warenkorb-Daten
   * @returns boolean - succes true / fail false
   */
  private api_update(reservation: Reservation): Promise<boolean> {
    const registration_data: BackendCacheRegistration = this.to_BackendCacheRegistration_object(reservation);
    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/${reservation.cache.id}`;

    return this.backend
      .put_with_token(url, registration_data)
      .then(() => true)
      .catch(() => false);
  }

  private async api_delete(reservation: Reservation): Promise<boolean> {
    if (!reservation?.cache.id) { return true; }

    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/${reservation.cache.id}`;

    return this.backend
      .delete_with_token(url)
      .then(() => true)
      .catch(() => false);
  }

  // ****************************************
  // ********* API functions end ************
  // ****************************************

  private to_BackendCacheRegistration_object(
    reservation: Reservation
  ): BackendCacheRegistration {
    return {
      registration_object: reservation,
      child_obj: reservation.cache.child_obj,
      other_participant: reservation.cache.other_participant,
      program_id: reservation.cache.program_id
    };
  }
}
